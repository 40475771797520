import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import loadjs from 'loadjs';
import addToMailChimp from 'gatsby-plugin-mailchimp';
import { CssTextField } from '../ContactUsForm/contactUsForm';
import * as styles from './emailSubscribeForm.module.css';

const EmailSubscribeForm = () => {
    const [success, setSuccess] = useState(false);
    const [recaptchaLoaded, setRecapthcaLoaded] = useState(false);

    useEffect(() => {
        loadjs(`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_CLIENT_ID}`, { 
          returnPromise: true,
          numRetries: 3
        })
          .then(() => { setRecapthcaLoaded(true); })
          .catch(() => { setRecapthcaLoaded(false); })
      }, []);

    return (
        <Formik
         initialValues={{ name: '', email: ''}}
         validate={values => {
           const errors = {};
           if (!values.email) {
             errors.email = 'Required';
           } else if (
             !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
           ) {
             errors.email = 'Invalid email address';
           }
           return errors;
         }}
         onSubmit={(values, { setSubmitting, setErrors }) => {
            window.grecaptcha.ready(function() {
              window.grecaptcha.execute(process.env.GATSBY_RECAPTCHA_CLIENT_ID, {action: 'submit'}).then(function(token) {
                addToMailChimp(values.email, {
                  FNAME: values.name.split(' ')[0],
                  LNAME: values.name.split(' ').length > 1 ? values.name.split(' ')[values.name.split(' ').length - 1] : ''
                }).then(function(response) {
                  setSubmitting(false);
                  console.log(response);
                  if (response.result === 'error') {
                    setErrors({
                        global: response.msg
                    });
                    setSuccess(false);
                  } else {
                    setSuccess(true);
                  }
                });
              });
            });
         }}
       >
         {({
           values,
           errors,
           touched,
           handleChange,
           handleBlur,
           handleSubmit,
           isSubmitting,
         }) => (
           <form onSubmit={handleSubmit} className={styles.form}>
              <CssTextField 
                type="text" 
                name="name" 
                onChange={handleChange}
                onBlur={handleBlur} 
                label="Name" 
                className={styles.input} 
                value={values.name}
                />
              <CssTextField 
                error={errors.email && touched.email} 
                type="text" 
                name="email" 
                onChange={handleChange}
                onBlur={handleBlur} 
                label="Email" 
                helperText={errors.email && touched.email ? 'Please enter a valid email.' : undefined} 
                className={styles.input} 
                value={values.email}
                />
              <div className={styles.submitContainer}>
                <Button type="submit" disabled={isSubmitting || !recaptchaLoaded} className={styles.submitButton}>
                  SUBSCRIBE
                </Button>
              </div>
              {errors.global ? <div dangerouslySetInnerHTML={{ __html: errors.global }} className={styles.errorMessage} /> : null}
              {success ? <div className={styles.successMessage}>Welcome to the Love Shack!</div> : null}
           </form>
         )}
       </Formik>
    )
}

export default EmailSubscribeForm;